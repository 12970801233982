import * as yup from "yup";

import Product from "../Product";
import { ProductDeserializer } from "./ProductDeserializer";

export enum PublicTransportTaxVariant {
	Taxfree = "2020-taxfree",
	Tax25 = "2020-25%",
}

export type MobilitySettings = {
	publicTransportTaxVariant?: PublicTransportTaxVariant;
} | null;

export class ProductMobility extends Product<MobilitySettings> {
	getDefaultSettings(): MobilitySettings {
		return {
			publicTransportTaxVariant: PublicTransportTaxVariant.Taxfree,
		};
	}

	static fromJson(data: any): ProductMobility {
		return new ProductMobilityDeserializer().deserialize(data);
	}

	static deserializeSettings(data: any): MobilitySettings {
		return new ProductMobilityDeserializer().deserializeSettings(data);
	}
}

export class ProductMobilityDeserializer extends ProductDeserializer<
	MobilitySettings,
	ProductMobility
> {
	deserialize(data: any): ProductMobility {
		return new ProductMobility(this.deserializeProps(data));
	}

	deserializeSettings(data: any): MobilitySettings {
		return settingsSchema.validateSync(data) as MobilitySettings; // TODO: make yup work with enums
	}
}

const settingsSchema = yup
	.object({
		publicTransportTaxVariant: yup
			.string()
			.oneOf(Object.values(PublicTransportTaxVariant)),
	})
	.nullable();
