import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import {
	Button,
	Checkbox,
	FormGroup,
	Label,
	NoAutoFillInput,
	Select,
	TextArea,
	FormContainer,
	ActionButton,
	RequiredMarker,
	QueryStateIndicators,
} from "@components";
import DatePicker from "@components/forms/DatePicker";
import InputValidation from "@components/forms/InputValidation";
import { QueryState, useAsyncCommand, useServices } from "@hooks";
import { User } from "@models";
import { NewUser, UpdatedUser } from "../../../models/User";
import Routes, { UserRouteParams } from "@service/navigation/routes";
import { createNewUser, createUpdatedUser } from "./util";
import { REQUIRED_ERROR_MESSAGE } from "@util/errors";
import { isValidEmail } from "@util/isEmailValid";
import UsersByDivisionSearchBar from "./UsersByDivisionSearchBar";
import _ from "lodash";
import InactiveUserExistsModal from "./InactiveUserExistsModal";
import { isBefore } from "date-fns";

type Props = {
	user?: User;
	handleOnSubmit?: (user: NewUser | UpdatedUser) => void;
	cmdState: QueryState<NewUser | UpdatedUser>;
};

export default function UserForm(props: Props) {
	const { params } = useRouteMatch<UserRouteParams>();
	const { customerId, divisionId } = params;
	const [inactiveUsers, setInactiveUsers] = useState<User[]>();
	const [fetchUsersByDivision, fetchUsersByDivisionQuery] = useAsyncCommand(
		async () => {
			const result = await userService.getUsersByDivision(divisionId, {
				limit: undefined,
			});
			return result.data;
		}
	);
	const [submitFormState, setSubmitFormState] = useState<QueryState<any>>(
		fetchUsersByDivisionQuery
	);

	useEffect(() => {
		setSubmitFormState(
			(preVal) =>
				({
					...preVal,
					state:
						fetchUsersByDivisionQuery.state === "success"
							? "idle"
							: fetchUsersByDivisionQuery.state,
				} as QueryState<any>)
		);
	}, [fetchUsersByDivisionQuery.state]);

	useEffect(() => {
		setSubmitFormState(props.cmdState);
	}, [props.cmdState.state]);

	const usersPath = Routes.Users.makePath({
		customerId: customerId,
		divisionId: divisionId,
	});

	const {
		control,
		setValue,
		handleSubmit,
		formState,
		watch,
		trigger,
		getValues,
	} = useForm<NewUser | UpdatedUser>({
		defaultValues: props.user ? createUpdatedUser(props.user) : createNewUser(),
		mode: "onChange",
	});

	const handleCancel = () => {
		setInactiveUsers(undefined);
	};
	const handleContinue = () => {
		props.handleOnSubmit?.(getValues());
		setInactiveUsers(undefined);
	};

	const { userService } = useServices();

	const [isSSOUser, setIsSSOUser] = useState<boolean | undefined>(
		props.user?.isSSOUser
	);

	const checkSSO = useCallback(
		_.debounce(async (email: string, signal: AbortSignal) => {
			const isSSOUser = await userService.checkSSO(email, signal);
			setIsSSOUser(isSSOUser);
		}, 500),
		[]
	);

	const email = watch("email");
	useEffect(() => {
		setIsSSOUser(undefined);
		const abortController = new AbortController();
		if (isValidEmail(email)) {
			void checkSSO(email, abortController.signal);
		}
		return () => abortController.abort();
	}, [email]);

	const handleSubmitUserForm = handleSubmit(
		async (data: NewUser | UpdatedUser) => {
			if (props.user) {
				props.handleOnSubmit?.(data);
			} else {
				const result = await fetchUsersByDivision();
				if (result.state === "success") {
					const users = result.data;
					const activeUser = users?.some(
						(user) =>
							user.personalNo === data.personalNo.trim() && user.isActive
					);
					if (activeUser) {
						props.handleOnSubmit?.(data);
					} else {
						const inactiveUsers = users?.filter(
							(user) =>
								user.personalNo === data.personalNo.trim() && !user.isActive
						);
						if (inactiveUsers.length) {
							setInactiveUsers([...inactiveUsers]);
						} else {
							props.handleOnSubmit?.(data);
						}
					}
				}
			}
		}
	);

	const onChangeDate = (day: Date, nameInput: keyof NewUser) => {
		// To trigger validation when date is cleared manually. When user is manually clearing the date, it will no longer
		// be a valid Date and day is set to  unsefined
		if (day === undefined) {
			setValue(nameInput, null, {
				shouldDirty: true,
			});
		}
	};

	const validateContractDates = (
		contractDate: "contractStart" | "contractEnd"
	) => {
		const contractStartDate = getValues("contractStart");
		const contractEndDate = getValues("contractEnd");
		return (
			!contractEndDate ||
			contractStartDate < contractEndDate ||
			(contractDate === "contractStart"
				? "Das Startdatum sollte vor dem Enddatum liegen."
				: "Das Enddatum sollte nach dem Startdatum liegen.")
		);
	};

	useEffect(() => {
		void trigger(["contractStart", "contractEnd"]);
	}, [watch("contractEnd"), watch("contractStart")]);

	return (
		<>
			{!!inactiveUsers?.length && (
				<InactiveUserExistsModal
					show={!!inactiveUsers.length}
					onCancel={handleCancel}
					onContinue={handleContinue}
					inactiveUsers={inactiveUsers}
				/>
			)}
			<FormContainer className="form">
				<Form onSubmit={handleSubmitUserForm}>
					<InputValidation error={formState.errors.title}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="title">
								Anrede
							</Label>
							<Col sm={8}>
								<Controller
									name="title"
									control={control}
									render={({ field }) => (
										<Select id="title" {...field}>
											<option value=""></option>
											<option value="Herr">Herr</option>
											<option value="Frau">Frau</option>
											<option value="Herr Dr.">Herr Dr.</option>
											<option value="Frau Dr.">Frau Dr.</option>
										</Select>
									)}
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.firstname}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="firstname">
								Vorname
								<RequiredMarker />
							</Label>
							<Col sm={8}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput {...field} id="firstname" maxLength="45" />
									)}
									control={control}
									name="firstname"
									rules={{ required: REQUIRED_ERROR_MESSAGE }}
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.lastname}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="lastname">
								Nachname
								<RequiredMarker />
							</Label>
							<Col sm={8}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput {...field} id="lastname" maxLength="45" />
									)}
									control={control}
									name="lastname"
									rules={{ required: REQUIRED_ERROR_MESSAGE }}
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.email}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="email">
								Email
								<RequiredMarker />
							</Label>
							<Col sm={8}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput {...field} id="email" maxLength="100" />
									)}
									control={control}
									name="email"
									rules={{
										required: REQUIRED_ERROR_MESSAGE,
										validate: (email: string) => {
											if (!isValidEmail(email)) {
												return "Die E-Mail-Adresse hat kein gültiges Format (z.B. Umlaute oder ungültige Zeichen)";
											}
											return true;
										},
									}}
								/>
								{isSSOUser && (
									<SSOHint>
										SSO-Nutzer: Das Login des Nutzers wird durch das
										SSO-Verfahren unseres Kunden verwaltet
									</SSOHint>
								)}
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.phone}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="phone">
								Telefon
							</Label>
							<Col sm={8}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput {...field} id="phone" maxLength="45" />
									)}
									control={control}
									name="phone"
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.personalNo}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="personalNo">
								Personalnummer
								<RequiredMarker />
							</Label>
							<Col sm={8}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput
											{...field}
											id="personalNo"
											maxLength="45"
										/>
									)}
									control={control}
									name="personalNo"
									rules={{ required: REQUIRED_ERROR_MESSAGE }}
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.externalId}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="externalId">
								Externe ID
							</Label>
							<Col sm={8}>
								<Controller
									render={({ field }) => (
										<NoAutoFillInput
											{...field}
											id="externalId"
											maxLength="100"
										/>
									)}
									control={control}
									name="externalId"
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<FormGroup as={Row}>
						<Label column sm={4} htmlFor="superiorUserId">
							Reisekostenprüfer
						</Label>
						<Col sm={8}>
							<Controller
								control={control}
								name="superiorUserId"
								render={({ field }) => (
									<UsersByDivisionSearchBar
										{...field}
										id="superiorUserId"
										currentUser={props.user ?? null}
										divisionId={divisionId}
										defaultUserSuperiorId={props.user?.superiorUserId ?? null}
										onChange={(superiorId) => {
											setValue("superiorUserId", superiorId, {
												shouldDirty: true,
											});
										}}
									/>
								)}
							></Controller>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<Label column sm={4} htmlFor="costCenter">
							Kostenstelle
						</Label>
						<Col sm={8}>
							<Controller
								render={({ field }) => (
									<NoAutoFillInput {...field} id="costCenter" maxLength="45" />
								)}
								control={control}
								name="costCenter"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<Label column sm={4} htmlFor="paymentReduction">
							Entgeltverzicht (€)
						</Label>
						<Col sm={8}>
							<Controller
								render={({ field }) => (
									<NoAutoFillInput
										{...field}
										id="paymentReduction"
										type="number"
										min="0"
										step="1"
									/>
								)}
								control={control}
								name="paymentReduction"
							/>
						</Col>
					</FormGroup>

					<FormGroup as={Row}>
						<Label column sm={4} htmlFor="workingDaysPerWeek">
							Arbeitstage pro Woche
						</Label>
						<Col sm={8}>
							<Controller
								control={control}
								render={({ field }) => (
									<NoAutoFillInput
										{...field}
										id="workingDaysPerWeek"
										type="number"
										min="0"
										step="1"
									/>
								)}
								name="workingDaysPerWeek"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<Label column sm={4} htmlFor="dateOfBirth">
							Geburtsdatum
						</Label>
						<Col sm={8}>
							<Controller
								control={control}
								name="dateOfBirth"
								render={({ field }) => (
									<DatePicker
										{...field}
										id="dateOfBirth"
										canClearDate
										onClearDate={() => {
											setValue("dateOfBirth", null, {
												shouldDirty: true,
											});
										}}
										onDayChange={(day: Date) =>
											onChangeDate(day, "dateOfBirth")
										}
									/>
								)}
							/>
						</Col>
					</FormGroup>

					<InputValidation error={formState.errors.contractStart}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="contractStart">
								Startdatum
							</Label>
							<Col sm={8}>
								<Controller
									control={control}
									name="contractStart"
									rules={{
										validate: () => validateContractDates("contractStart"),
									}}
									render={({ field }) => (
										<DatePicker
											{...field}
											id="contractStart"
											onDayChange={(day: Date) =>
												onChangeDate(day, "contractStart")
											}
										/>
									)}
								/>
							</Col>
						</FormGroup>
					</InputValidation>
					<InputValidation error={formState.errors.contractEnd}>
						<FormGroup as={Row}>
							<Label column sm={4} htmlFor="contractEnd">
								Befristet bis
							</Label>
							<Col sm={8}>
								<Controller
									control={control}
									name="contractEnd"
									rules={{
										validate: () => validateContractDates("contractEnd"),
									}}
									render={({ field }) => (
										<DatePicker
											{...field}
											id="contractEnd"
											placeholder="Unbefristet"
											canClearDate
											onClearDate={() => {
												setValue("contractEnd", null, {
													shouldDirty: true,
												});
											}}
											onDayChange={(day: Date) =>
												onChangeDate(day, "contractEnd")
											}
										/>
									)}
								/>
							</Col>
						</FormGroup>
					</InputValidation>

					<FormGroup as={Row}>
						<Label column sm={4} htmlFor="comment">
							Kommentar
						</Label>
						<Col sm={8}>
							<Controller
								render={({ field }) => (
									<TextArea {...field} id="comment" rows="5" maxLength="512" />
								)}
								control={control}
								name="comment"
							/>
						</Col>
					</FormGroup>
					<FormGroup as={Row}>
						<Label column sm={4} htmlFor="commentInternal">
							Kommentar (intern)
						</Label>
						<Col sm={8}>
							<Controller
								render={({ field }) => (
									<TextArea
										{...field}
										id="commentInternal"
										rows="5"
										maxLength="512"
									/>
								)}
								control={control}
								name="commentInternal"
							/>
						</Col>
					</FormGroup>

					<Controller
						control={control}
						name="ownVouchersEnabled"
						render={({ field }) => (
							<Checkbox
								{...field}
								label="Belegeeinreichung erlauben"
								onChange={(e: any) => field.onChange(e.target.checked)}
							/>
						)}
					/>

					<Controller
						control={control}
						name="isTestUser"
						render={({ field }) => (
							<Checkbox
								{...field}
								label="Ist Test-Benutzer"
								onChange={(e: any) => field.onChange(e.target.checked)}
							/>
						)}
					/>

					<AutoSalutationWarning
						user={props.user}
						startDate={watch("contractStart")}
					/>
					<ButtonContainer>
						<Link to={usersPath}>
							<Button type="button" variant="outline-secondary">
								Zurück
							</Button>
						</Link>
						<ActionButton
							type="submit"
							query={submitFormState}
							disabled={!formState.isValid || !formState.isDirty}
							successContent={"Gespeichert"}
						>
							Speichern
						</ActionButton>
					</ButtonContainer>
				</Form>
			</FormContainer>
			<QueryStateIndicators queryState={props.cmdState} />
		</>
	);
}

function AutoSalutationWarning(props: { user?: User; startDate: Date | null }) {
	const { user, startDate } = props;
	const isSalutated = !!user?.salutated;
	if (isSalutated) {
		return null;
	}

	if (startDate && !isBefore(startDate, new Date())) {
		return null;
	}

	if (user && String(user.contractStart) === String(startDate)) {
		return null;
	}

	return (
		<Alert style={{ marginTop: "1rem" }} variant="warning">
			Der / die Mitarbeiter:in wird beim Speichern automatisch eingeladen, da
			das Startdatum heute ist oder in der Vergangenheit liegt.
		</Alert>
	);
}

const SSOHint = styled.div``;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
