import React, { useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";

import "react-day-picker/dist/style.css";
import styled from "styled-components";
import Button from "../Button";
import { FORMAT, formatDate, MONTHS, parseDate } from "./datePickerUtils";
import { FaTimes as ClearIcon } from "react-icons/fa";
import { Select } from "@components";
import { colors } from "@theme/theming";
import { useOutsideClick } from "@hooks";

const fromMonth = new Date("1900-01-01");
const toMonth = new Date("2050-12-31");

function CalendarHeader(props: {
	date: Date;
	onChange: (selectedDate: Date) => void;
	onSelectToday: () => void;
	onClose: () => void;
}) {
	const years = [];
	for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
		years.push(i);
	}

	return (
		<CalendarHeaderContainer>
			<SelectViewDateContainer>
				<Select
					name="month"
					title="Monat"
					onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
						props.onChange(
							new Date(
								props.date.getFullYear(),
								Number.parseInt(e.target.value)
							)
						);
					}}
					value={props.date.getMonth()}
				>
					{MONTHS.map((month, i) => (
						<option key={month} value={i}>
							{month}
						</option>
					))}
				</Select>
				<Select
					name="year"
					title="Jahr"
					onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
						props.onChange(
							new Date(Number.parseInt(e.target.value), props.date.getMonth())
						)
					}
					value={props.date.getFullYear()}
				>
					{years.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</Select>
			</SelectViewDateContainer>
			<Button
				size="sm"
				type="button"
				variant="secondary"
				onClick={props.onSelectToday}
			>
				Heute
			</Button>
		</CalendarHeaderContainer>
	);
}

type Props = {
	id?: string;
	style?: Record<string, unknown>;
	name?: string;
	value: Date | undefined | null;
	placeholder?: string;
	selectsRange?: boolean;
	canClearDate?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onDayChange?: (date: Date) => void;
	onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
	onClearDate?: () => void;
	disabled?: boolean;
	isDayDisabled?: (day: Date) => boolean;
};

const DatePicker = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
	// much of the code is taken from the example at https://react-day-picker.js.org/guides/input-fields
	const { onClearDate, id, value, disabled } = props;
	const [selectedDate, setSelectedDate] = useState(value ?? new Date());

	const [isDialogOpen, setDialogOpen] = useState(false);
	const [shakeInput, setShakeInput] = useState(false);

	// we need to track the input state separately, since during editing we won't have a valid date
	const [inputDate, setInputDate] = useState<string>("");
	const inputRef = useRef<HTMLInputElement>(null);
	const [isCollapsing, setIsCollapsing] = useState(false);

	const closePopper = () => {
		setIsCollapsing(true);
		setDialogOpen(false);
	};

	const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
		if (
			!isCollapsing &&
			e.currentTarget.selectionStart === e.currentTarget.selectionEnd // don't open dialog if we're selecting text with the mouse cursor
		) {
			setDialogOpen(true);
		}
	};

	const propagateSelectedChange = (newDate: Date) => {
		props.onDayChange?.(newDate);
		props.onChange?.(newDate as any);
	};

	const handleManualInput = (newVal: string) => {
		setInputDate(newVal);
		const newDate = parseDate(newVal);
		if (newDate) {
			setSelectedDate(newDate);
			propagateSelectedChange(newDate);
		}
		if (newVal === "") {
			props.onClearDate?.();
		}
	};

	const handleInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if ((e.key === "Esc" || e.key === "Escape") && isDialogOpen) {
			closePopper();
		} else if (e.key === "Enter") {
			setDialogOpen((preVal) => !preVal);
		}
	};

	const inValidInput = (newDateValue: string) => {
		const cursorPosition = inputRef.current?.selectionStart;
		const isDeleted = newDateValue.length < inputDate.length;
		setShakeInput(true);
		setTimeout(() => {
			setShakeInput(false);
		}, 500);
		setTimeout(() => {
			if (inputRef.current && cursorPosition) {
				inputRef.current.selectionStart = cursorPosition - (isDeleted ? -1 : 1);
				inputRef.current.selectionEnd = cursorPosition - (isDeleted ? -1 : 1);
			}
		}, 0);
	};

	const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newDateValue = e.target.value;
		const dayRegex = /^(3[01]|[1-2][0-9]|0?[1-9])$/;
		const monthRegex = /^(1[0-2]|0?[1-9])$/;
		const yearRegex = /^([0-9]{4})$/;
		const date = newDateValue.split(".");
		const isValidDay = dayRegex.test(date[0]) || !date[0] || date[0].length < 2;
		const isValidMonth =
			monthRegex.test(date[1]) || !date[1] || date[1].length < 2;
		const isValidYear =
			yearRegex.test(date[2]) || !date[2] || date[2].length < 4;
		if (
			(date.length === 1 && isValidDay) ||
			(date.length === 2 && isValidDay && isValidMonth) ||
			(date.length === 3 && isValidDay && isValidMonth && isValidYear)
		) {
			handleManualInput(newDateValue);
		} else {
			inValidInput(newDateValue);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	React.useImperativeHandle(ref, () => inputRef.current!);
	const calendarRef = useOutsideClick<HTMLDivElement>(() => {
		setDialogOpen(false);
	});

	useEffect(() => {
		setIsCollapsing(false);
	}, [isCollapsing]);

	useEffect(() => {
		if (props.value) {
			const date = parseDate(inputDate);
			if (
				inputDate === "" ||
				(date && formatDate(date) !== formatDate(props.value))
			) {
				setInputDate(formatDate(props.value));
			}
		}
	}, [props.value]);

	return (
		<Wrapper>
			<CustomDateInputContainer>
				<DateInput
					shake={shakeInput}
					ref={inputRef}
					className="form-control"
					id={id}
					disabled={disabled}
					onBlur={(e) => {
						props.onBlur?.(e);
						if (props.value) {
							setInputDate(formatDate(props.value));
						}
					}}
					placeholder={props.placeholder ?? FORMAT}
					value={inputDate}
					onChange={onDateChange}
					onClick={handleInputClick}
					onKeyUp={handleInputKeyUp}
				/>

				{props.canClearDate && (
					<ClearButton
						disabled={!props.value}
						onClick={() => {
							onClearDate?.();
							setInputDate("");
						}}
						title="Zurücksetzen"
					>
						<ClearIcon />
					</ClearButton>
				)}
			</CustomDateInputContainer>
			{isDialogOpen && (
				<PopperContainer
					tabIndex={-1}
					className="dialog-sheet"
					role="dialog"
					ref={calendarRef}
				>
					<CalendarHeader
						date={selectedDate}
						onChange={setSelectedDate}
						onSelectToday={() => {
							const now = new Date();
							propagateSelectedChange(now);
							setSelectedDate(now);
							closePopper();
						}}
						onClose={closePopper}
					/>
					<StyledDatePicker
						mode="single"
						selected={props.value ?? undefined}
						month={selectedDate}
						onMonthChange={setSelectedDate}
						disabled={props.isDayDisabled}
						onSelect={(day) => {
							if (day) {
								propagateSelectedChange(day);
							}
							closePopper();
						}}
						modifiersStyles={{
							selected: {
								background: colors.primary.p200,
								color: colors.white,
								borderColor: "transparent",
							},
							today: {
								background: colors.gray.g100,
								color: colors.primary.p200,
							},
						}}
					/>
				</PopperContainer>
			)}
		</Wrapper>
	);
});

export default DatePicker;

const CalendarHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const StyledDatePicker = styled(DayPicker)`
	button {
		&:focus {
			border-color: transparent;
		}
	}
`;

const SelectViewDateContainer = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	align-items: baseline;
`;

const ClearButton = styled(Button).attrs({
	variant: "outline-secondary",
	size: "sm",
})`
	border: 0;
	padding-inline: 7px !important;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	color: ${colors.gray.g400};
	font-size: 18px !important;
	&:hover,
	&:active,
	&:focus {
		border-color: transparent !important;
		background: transparent !important;
		box-shadow: none !important;
		color: ${colors.primary.p200} !important;
	}
	&:disabled:hover,
	&:disabled {
		background: transparent;
		color: ${colors.gray.g300} !important;
	}
`;

const CustomDateInputContainer = styled.div`
	position: relative;
`;

const PopperContainer = styled.div.attrs({
	tabIndex: -1,
	className: "dialog-sheet",
	role: "dialog",
})`
	background: ${colors.white};
	border: solid 1px ${colors.gray.g200};
	border-radius: 5px;
	z-index: 4;
	position: absolute;
	margin-top: 2px;
	padding: 8px;
`;

const Wrapper = styled.div`
	display: inline-block;
`;

type DateInputProps = {
	shake: boolean;
};

const DateInput = styled.input<DateInputProps>`
	padding-right: 28px;
	border-color: ${colors.gray.g200};
	display: inline;
	&:active,
	&:focus {
		box-shadow: 0 0 0 0.2rem ${colors.primary.p200}80 !important;
	}
	${(props) => props.shake && `animation: shake 0.5s;`}
	@keyframes shake {
		0% {
			transform: translateX(2px);
		}
		10% {
			transform: translateX(-2px);
		}
		20% {
			transform: translateX(2px);
		}
		30% {
			transform: translateX(-2px);
		}
		40% {
			transform: translateX(2px);
		}
		50% {
			transform: translateX(-2px);
		}
		60% {
			transform: translateX(2px);
		}
		70% {
			transform: translateX(-2px);
		}
		80% {
			transform: translateX(2px);
		}
		90% {
			transform: translateX(-2px);
		}
		100% {
			transform: translateX(2px);
		}
	}
`;
