import { Properties } from "@util/types";
import * as yup from "yup";

export const userTypes = [
	"backofficeUser",
	"appUser",
	"publicAPI",
	"job",
	"migration",
	"portalUser",
	"portalUserInternal",
	"legacyPortalUser",
] as const;
export type UserType = typeof userTypes[number];

type PrincipalDetails = {
	fullName?: string;
	customerId?: string;
	divisionId?: string;
	userId?: string;
};

export class DomainEvent {
	constructor(props: Properties<DomainEvent>) {
		this.type = props.type;
		this.occurredAt = props.occurredAt;
		this.principalUri = props.principalUri;
		this.principalDetails = props.principalDetails;
		this.origin = props.origin;
		this.data = props.data;
	}

	readonly type: string;
	readonly occurredAt: Date;
	readonly principalUri: string;
	readonly principalDetails: PrincipalDetails;
	readonly origin?: string;
	readonly data: object;

	static fromJson(data: any): DomainEvent {
		const domainEvent = domainEventSchema.validateSync(data);
		return new DomainEvent({
			type: domainEvent.type,
			occurredAt: new Date(domainEvent.occurredAt),
			principalUri: domainEvent.principalUri,
			principalDetails: domainEvent.principalDetails,
			origin: domainEvent.origin,
			data: domainEvent.data,
		});
	}
}

const principalDetailsSchema = yup
	.object({
		fullName: yup.string().optional(),
		customerId: yup.string().uuid().optional(),
		divisionId: yup.string().uuid().optional(),
		userId: yup.string().uuid().optional(),
	})
	.required();

const domainEventSchema = yup.object({
	type: yup.string().required(),
	occurredAt: yup.date().required().typeError("invalid date"),
	principalUri: yup.string().required(),
	principalDetails: principalDetailsSchema,
	origin: yup.string(),
	data: yup.mixed().required(),
});
