import { Properties } from "@util/types";
import * as yup from "yup";

export class EmployerPaidRailcardSettings {
	constructor(props: Properties<EmployerPaidRailcardSettings>) {
		this.financing = props.financing;
	}

	readonly financing: Readonly<{
		expenses: number;
		commuting: number;
		fringeBenefits: number;
	}>;

	public static fromJson(data: any): EmployerPaidRailcardSettings {
		const props = employerPaidRailcardSettingsSchema.validateSync(data);
		return new EmployerPaidRailcardSettings({ ...props });
	}
}

const employerPaidRailcardSettingsSchema = yup.object({
	financing: yup
		.object({
			fringeBenefits: yup.number().required(),
			expenses: yup.number().required(),
			commuting: yup.number().required(),
		})
		.required(),
});
