import {
	FaCheck,
	FaFlask,
	FaHourglassEnd,
	FaHourglassStart,
	FaUserTimes,
} from "react-icons/fa";
import { MdPause } from "react-icons/md";
import { Icon } from "@components";

const UserStatusIcon = {
	Active: FaCheck,
	TestUser: FaFlask,
	Expired: FaHourglassEnd,
	NotStarted: FaHourglassStart,
	Salutated: Icon.Mail,
	Vouchers: Icon.Vouchers,
	NeverLoggedIn: FaUserTimes,
	Ok: Icon.Success,
	ActiveIncident: MdPause,
};

export default UserStatusIcon;
