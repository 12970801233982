import { Properties } from "@util/types";
import * as yup from "yup";

type AccountingMonthStatus = "closed" | "locked" | "open";
type AccountingMonthEventType =
	| "closed"
	| "locked"
	| "reopened"
	| "unlocked"
	| "unclosed";

export default class AccountingMonth {
	constructor(props: Properties<AccountingMonth>) {
		this.month = props.month;
		this.status = props.status;
		this.events = props.events;
		this.divisionId = props.divisionId;
	}

	readonly month: Date;
	readonly status: AccountingMonthStatus;
	readonly events: ReadonlyArray<AccountingMonthEvent>;
	readonly divisionId: string;

	public static fromJson(data: any, divisionId: string): AccountingMonth {
		const props = accountingMonthSchema.validateSync(data);
		return new AccountingMonth({
			...props,
			divisionId,
			status: props.status as AccountingMonthStatus,
			events: props.events.map(
				(props) =>
					new AccountingMonthEvent({
						time: props.time,
						type: props.type as AccountingMonthEventType,
						id: props.id,
					})
			),
		});
	}
}

export class AccountingMonthEvent {
	constructor(
		props: Pick<Properties<AccountingMonthEvent>, "type" | "time" | "id">
	) {
		this.type = props.type;
		this.time = props.time;
		this.id = props.id;
	}

	readonly type: AccountingMonthEventType;
	readonly time: Date;
	readonly id: string;

	get displayName(): string {
		switch (this.type) {
			case "closed":
				return "abgeschlossen";
			case "unclosed":
				return "wieder gesperrt";
			case "locked":
				return "gesperrt";
			case "reopened": // legacy
				return "wieder geöffnet";
			case "unlocked":
				return "entsperrt";
		}
		throw new Error("not implemented");
	}
}

const accountingMonthSchema = yup.object({
	month: yup.date().required(),
	status: yup
		.string()
		.required()
		.oneOf<AccountingMonthStatus>(["closed", "locked", "open"]),
	events: yup
		.array(
			yup.object({
				type: yup
					.string()
					.required()
					.oneOf<AccountingMonthEventType>([
						"closed",
						"locked",
						"reopened",
						"unclosed",
						"unlocked",
					]),
				time: yup.date().required(),
				id: yup.string().required(),
			})
		)
		.required(),
});
