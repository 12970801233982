import { Icon, IconButton, ProductIcon } from "@components";
import { formatDate } from "@components/forms/datePickerUtils";
import { BudgetMaster, User } from "@models";
import { UserStatus } from "@models/User";
import Routes from "@service/navigation/routes";
import { formatDate as formatDateLong } from "@service/util";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UserStatusIcon from "./UserStatusIcon";
import {
	getStatusFlags,
	groupBudgetMastersByProduct,
	summarizeBMs,
} from "./util";
import { isBefore } from "date-fns";
import { colors } from "@theme/theming";

type Props = {
	user: User;
	budgetMasters: BudgetMaster[];
	userStatus: UserStatus;
};

export default function ExpandedUserDetails(props: Props) {
	const { user, budgetMasters, userStatus } = props;
	const groupedBudgetMasters = groupBudgetMastersByProduct(budgetMasters);

	const budgetsUrl = Routes.UserProducts.makePath({
		userId: user.userId,
		customerId: user.customerId,
		divisionId: user.divisionId,
	});

	const basicDataUrl = Routes.UserBasicData.makePath({
		userId: user.userId,
		customerId: user.customerId,
		divisionId: user.divisionId,
	});

	const budgetMastersElements = (
		<div>
			<SectionHeader>
				<UserDetailsLink to={budgetsUrl}>Aktuelle Budgets</UserDetailsLink>
			</SectionHeader>
			<BudgetMasterGrid>
				{groupedBudgetMasters.map(
					(b) =>
						b.budgetMasters && (
							<React.Fragment key={b.id}>
								<ProductIcon productId={b.id} />
								<div>{b.productName}</div>
								<div>{summarizeBMs(b.budgetMasters)}</div>
							</React.Fragment>
						)
				)}
				{groupedBudgetMasters.every((g) => !g.budgetMasters) && "Keine Budgets"}
			</BudgetMasterGrid>
		</div>
	);

	const getContractInterval = (): string => {
		const start = formatDate(user.contractStart);
		const end = user.contractEnd ? formatDate(user.contractEnd) : "Unbefristet";

		return `${start} - ${end}`;
	};

	const basicDataElements = (
		<>
			<SectionHeader>
				<UserDetailsLink to={basicDataUrl}>Stammdaten</UserDetailsLink>
			</SectionHeader>
			<div>Erstellt: {formatDate(user.created)}</div>
			<div>Befristung: {getContractInterval()}</div>
			<div>Aktualisiert: {formatDate(user.updated)}</div>
			<div>
				Telefon:{" "}
				{user.phone ? <a href={`tel:${user.phone}`}>{user.phone}</a> : "-"}
			</div>
			<div>Anzahl Arbeitstage: {user.workingDaysPerWeek ?? "-"}</div>
			<div>Kostenstelle: {user.costCenter}</div>
		</>
	);

	const statusFlags = getStatusFlags(user);
	const getUserStatus = () => {
		switch (userStatus) {
			case UserStatus.Confirmed:
				return "Aktiv";
			case UserStatus.ForceChangePassword:
				return "Benutzer muss sich mit empfangenen Anmeldedaten einloggen und Passwort vergeben";
			case UserStatus.ResetRequired:
				return "Passwort muss zurückgesetzt werden";
			case UserStatus.Unknown:
				return "Unbekannt";
			default:
				return userStatus;
		}
	};
	const statusElements = (
		<>
			<SectionHeader>Status</SectionHeader>
			{statusFlags.nonTestUser ? (
				<Ok text="Kein Test-Benutzer" />
			) : (
				<NotOk text="Test-Benutzer" icon={<UserStatusIcon.TestUser />} />
			)}

			{statusFlags.uploadAllowed ? (
				<Ok text="Hochladen erlaubt" />
			) : (
				<NotOk
					text="Hochladen deaktiviert"
					icon={<UserStatusIcon.Vouchers />}
				/>
			)}

			{statusFlags.active ? (
				<Ok text="Benutzer aktiviert" />
			) : (
				<NotOk text="Benutzer deaktiviert" icon={<UserStatusIcon.Active />} />
			)}

			{statusFlags.nonExpired ? (
				statusFlags.contractStarted ? (
					<Ok text="Nicht abgelaufen" />
				) : (
					<NotOk
						text="Vetragsbeginn liegt in der Zukunft"
						icon={<UserStatusIcon.NotStarted />}
					/>
				)
			) : (
				<NotOk text="Befristung abgelaufen" icon={<UserStatusIcon.Expired />} />
			)}

			{statusFlags.salutated ? (
				<Ok text="Eingeladen" />
			) : (
				<NotOk text="Nicht eingeladen" icon={<UserStatusIcon.Salutated />} />
			)}
			{statusFlags.hasLoggedIn ? (
				<Ok text="Schon angemeldet" />
			) : (
				<NotOk
					text="Noch nicht angemeldet"
					icon={<UserStatusIcon.NeverLoggedIn />}
				/>
			)}

			{!!user.firstLogin && (
				<div title="firstLogin">
					Erster Login:{" "}
					{isBefore(user.firstLogin, new Date("2023-11-01"))
						? "vor November 2023" // the dates before November 2023 (when we started tracking this) might be not the first login, hide them to not confuse the operations team
						: formatDateLong(user.firstLogin)}
				</div>
			)}

			{!!user.lastLoginWeb && (
				<div title="lastLoginWeb">
					Letzter Login (Web): {formatDateLong(user.lastLoginWeb)}
				</div>
			)}

			{!!user.lastLoginMobile && (
				<div title="lastLoginMobile">
					Letzter Login (App): {formatDateLong(user.lastLoginMobile)}
				</div>
			)}

			{!!user.lastAppVersion && (
				<div title="lastAppVersion">
					Verwendete App-Version: {user.lastAppVersion}
				</div>
			)}

			{!!userStatus && (
				<div title="userStatus">Anmeldestatus: {getUserStatus()}</div>
			)}

			{!!user.lastActiveIncident && (
				<div title="auszeit">
					Auszeit: von {user.lastActiveIncident.interval.start} bis{" "}
					{user.lastActiveIncident.interval.end}
				</div>
			)}

			<div title="isSSOUser">
				{user.isSSOUser ? "Ist SSO-Nutzer" : "Kein SSO-Nutzer"}
			</div>
		</>
	);

	return (
		<Container>
			<div>{budgetMastersElements}</div>
			<div>{basicDataElements}</div>
			<div>{statusElements}</div>
		</Container>
	);
}

const NotOk = ({ text, icon }: { text: string; icon: React.ReactElement }) => {
	return (
		<div>
			<span style={{ color: colors.danger.d300 }}>{icon}</span>
			{text}
		</div>
	);
};

const Ok = ({ text }: { text: string }) => (
	<div>
		<span
			css={`
				color: ${colors.success.s300};
			`}
		>
			<UserStatusIcon.Ok />{" "}
		</span>
		{text}
	</div>
);

const BudgetMasterGrid = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	row-gap: 4px;
	column-gap: 8px;
	align-items: center;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	padding: 8px 16px 32px 16px;
	justify-content: space-evenly;
`;

const SectionHeader = styled.h5``;

const UserDetailsLink = ({
	to,
	children,
}: {
	to: string;
	children: React.ReactNode;
}) => (
	<Link to={to} style={{ color: colors.primary.p200 }}>
		<IconButton
			css={`
				margin: 0px;
			`}
		>
			<Icon.Link />
		</IconButton>
		{children}
	</Link>
);
