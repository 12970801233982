import { Division } from "@models";
import { NewDivision, UpdatedDivision } from "@models/Division";
import { DivisionStatus } from "@models/DivisionStatus";
import axios from "axios";
import * as apiService from "../api.service";
import { DeleteDivisionWithVoucherError } from "./errors";
import {
	getAssetDivisionById,
	upsertAssetDivision,
} from "@service/assetDivision";
import _ from "lodash";
import { AssetDivision } from "@models/AssetDivision";

export async function getDivisionById(divisionId: string): Promise<Division> {
	const division = await apiService.GET("division/" + divisionId);
	const assetDivision = await getAssetDivisionById(divisionId);
	return Division.fromJson({
		...division.data,
		providerSettings: assetDivision?.providerSettings,
	});
}

export async function getDivisions(customerId: string): Promise<Division[]> {
	const params = new URLSearchParams();
	params.append("customerId", customerId);

	const response = await apiService.GET(`division`, {
		params,
	});
	return (response.data as any[]).map(Division.fromJson);
}

export async function addDivision(
	customerId: string,
	division: NewDivision
): Promise<Division> {
	const { providerSettings, ...rest } = division;
	const response = await apiService.POST(
		`/customer/${customerId}/division`,
		rest
	);
	const newDivision = Division.fromJson(response.data);
	if (!providerSettings) {
		return newDivision;
	}
	await upsertAssetDivision(
		AssetDivision.fromJson({
			id: newDivision.divisionId,
			name: newDivision.name,
			customerId: newDivision.customerId,
			status: newDivision.status,
			providerSettings: providerSettings,
		})
	);
	return Division.fromJson({ ...newDivision, providerSettings });
}

export async function updateDivision(
	divisionId: string,
	division: UpdatedDivision,
	oldDivision: Division
): Promise<Division> {
	const { providerSettings, ...rest } = division;
	const response = await apiService.PUT(`/division/${divisionId}`, rest);
	const updatedDivision = Division.fromJson(response.data);
	if (_.isEqual(providerSettings, oldDivision.providerSettings)) {
		return { ...updatedDivision, providerSettings };
	}
	await upsertAssetDivision(
		AssetDivision.fromJson({
			id: updatedDivision.divisionId,
			name: updatedDivision.name,
			customerId: updatedDivision.customerId,
			status: updatedDivision.status,
			providerSettings: providerSettings,
		})
	);
	return Division.fromJson({
		...updatedDivision,
		providerSettings,
	});
}

export async function updateDivisionStatus(
	divisionId: string,
	status: DivisionStatus
): Promise<void> {
	if (status === DivisionStatus.Trial)
		throw new Error(
			`Unexpected updateDivisionStatus input: ${status} on division ${divisionId}`
		);

	const statusToPathMap: Record<DivisionStatus, string> = {
		[DivisionStatus.Active]: "activate",
		[DivisionStatus.Archived]: "archive",
		[DivisionStatus.Deactivated]: "deactivate",
		[DivisionStatus.Trial]: "unknown",
	};

	await apiService.PUT(`division/${divisionId}/${statusToPathMap[status]}`);
}

export async function deleteDivision(
	customerId: string,
	divisionId: string
): Promise<number> {
	try {
		return (
			await apiService.DELETE(`/customer/${customerId}/division/${divisionId}`)
		).data;
	} catch (error) {
		console.log("deletedivisionError", error);
		if (
			axios.isAxiosError(error) &&
			error.response?.data.type === "division-with-vouchers"
		) {
			throw new DeleteDivisionWithVoucherError();
		}
		throw error;
	}
}
