import { deserializeDate } from "@util";
import { Properties } from "@util/types";
import * as yup from "yup";
import PricingRecord, {
	PricingSettings,
	pricingSettingsSchema,
} from "./PricingRecord";
import { providerSettingsSchema } from "./AssetDivision";
import { DivisionStatus } from "./DivisionStatus";

export default class Division {
	constructor(props: Properties<Division>) {
		this.divisionId = props.divisionId;
		this.customerId = props.customerId;
		this.isMainDivision = props.isMainDivision;
		this.name = props.name;
		this.status = props.status;
		this.street = props.street;
		this.zip = props.zip;
		this.city = props.city;
		this.country = props.country;
		this.phone = props.phone;
		this.fax = props.fax;
		this.created = props.created;
		this.updated = props.updated;
		this.stid = props.stid;
		this.ustid = props.ustid;
		this.reportType = props.reportType;
		this.financialSoftwareId = props.financialSoftwareId;
		this.excessBudgetSettings = props.excessBudgetSettings;
		this.pricingSettings = props.pricingSettings;
		this.autoLockDayOfMonth = props.autoLockDayOfMonth;
		this.comment = props.comment;
		this.reviewSettings = props.reviewSettings;
		this.hasWebAppAccess = props.hasWebAppAccess;
		this.allowThirdPartyVouchers = props.allowThirdPartyVouchers;
		this.providerSettings = props.providerSettings;
	}

	readonly divisionId: string;
	readonly customerId: string;
	readonly name: string;
	readonly status: DivisionStatus;
	readonly isMainDivision: boolean | null;
	readonly street: string | null;
	readonly zip: string | null;
	readonly city: string | null;
	readonly country: string | null;
	readonly phone: string | null;
	readonly fax: string | null;
	readonly created: Date | null;
	readonly updated: Date | null;
	readonly stid: string | null;
	readonly ustid: string | null;
	readonly reportType: string | null;
	readonly financialSoftwareId: string | null;
	readonly excessBudgetSettings: ExcessBudgetSettings;
	readonly pricingSettings: PricingSettings | null;
	readonly autoLockDayOfMonth: number | null;
	readonly comment: string | null;
	readonly reviewSettings: ReviewSettings | null;
	readonly hasWebAppAccess: boolean;
	readonly allowThirdPartyVouchers: boolean;
	readonly providerSettings?: {
		readonly jobrad?: {
			readonly frameContractId: number;
			readonly companies: JobRadCompany[];
		};
	};

	private static schema = yup
		.object({
			divisionId: yup.string().required(),
			customerId: yup.string().required(),
			name: yup.string().required(),
			status: yup.string().oneOf(Object.values(DivisionStatus)).defined(),
			isMainDivision: yup.boolean().defined().nullable(),
			street: yup.string().defined().nullable(),
			zip: yup.string().defined().nullable(),
			city: yup.string().defined().nullable(),
			country: yup.string().defined().nullable(),
			phone: yup.string().defined().nullable(),
			fax: yup.string().defined().nullable(),
			created: yup.date().defined().nullable(),
			updated: yup.date().defined().nullable(),
			stid: yup.string().defined().nullable(),
			ustid: yup.string().defined().nullable(),
			reportType: yup.string().defined().nullable(),
			financialSoftwareId: yup.string().defined().nullable(),
			excessBudgetSettings: yup.object({
				savingPeriodStartMonth: yup
					.number()
					.min(1)
					.max(12)
					.defined()
					.nullable(),
				expirationTransitionTimeInMonths: yup
					.number()
					.min(1)
					.max(6)
					.defined()
					.nullable(),
			}),
			autoLockDayOfMonth: yup.number().defined().nullable(),
			pricingSettings: pricingSettingsSchema.nullable(),
			comment: yup.string().defined().nullable(),
			reviewSettings: yup
				.object({
					shouldReviewTrips: yup.boolean().defined(),
					minAmountToReviewTrips: yup.number().defined().nullable(),
					maxTripAgeInDaysForAutoApproval: yup.number().defined().nullable(),
				})
				.nullable(),
			hasWebAppAccess: yup.boolean().defined(),
			allowThirdPartyVouchers: yup.boolean().required(),
			providerSettingsSchema: providerSettingsSchema,
		})
		.required();

	public static fromJson(data: any): Division {
		const validated = Division.schema.validateSync(data);
		return new Division({
			...validated,
			pricingSettings: validated.pricingSettings
				? validated.pricingSettings.map(PricingRecord.fromJson)
				: null,
			created: deserializeDate.nullable.dateTime(data["created"]),
			updated: deserializeDate.nullable.dateTime(data["updated"]),
		});
	}
}

type JobRadCompany = {
	readonly id: number;
	readonly url: string;
};

type ExcessBudgetSettings = {
	readonly savingPeriodStartMonth: number | null;
	readonly expirationTransitionTimeInMonths: number | null;
};

type ReviewSettings = {
	shouldReviewTrips: boolean;
	minAmountToReviewTrips: number | null;
	maxTripAgeInDaysForAutoApproval: number | null;
};

export type NewDivision = Pick<
	Division,
	| "name"
	| "status"
	| "isMainDivision"
	| "street"
	| "zip"
	| "city"
	| "country"
	| "phone"
	| "fax"
	| "stid"
	| "ustid"
	| "comment"
	| "reviewSettings"
	| "providerSettings"
>;

export type UpdatedDivision = Pick<
	Division,
	| "name"
	| "customerId"
	| "isMainDivision"
	| "street"
	| "zip"
	| "city"
	| "country"
	| "phone"
	| "fax"
	| "stid"
	| "ustid"
	| "excessBudgetSettings"
	| "autoLockDayOfMonth"
	| "pricingSettings"
	| "comment"
	| "reviewSettings"
	| "hasWebAppAccess"
	| "allowThirdPartyVouchers"
	| "providerSettings"
>;
